import React from 'react'

import IconButton from '../Buttons/IconButton'

const PageHero = (props) => (
  <section
    className="page-hero"
    style={{backgroundColor: `${props.bgColor}`}}
  >
    <div
      className="page-hero__image"
      style={{backgroundImage: `url(${props.bgImage})`}}
    />

    <div className="page-hero__content">
      {props.children}
    </div>

    <IconButton />
  </section>
)

export default PageHero
