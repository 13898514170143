import React from 'react'
import Emoji from 'a11y-react-emoji'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const IconButton = (props) => (
  <div className="scroll-button">
    <AnchorLink href="#main-content" className="scroll-button__button">
      <Emoji symbol="👇" label="scroll down" />
    </AnchorLink>
  </div>
)

export default IconButton
